
import React, { useState , useEffect } from "react";
import { useStaticQuery , graphql } from "gatsby";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import window from 'global'

var showData = '';
const Footer = () => {
    
    // Querying and storing Data Stored in Gatsby GraphQL query.

    const data_query = useStaticQuery(graphql`
        query{
            wpgraphql {
                contactSections {
                    nodes {
                        acfContact {
                            title
                            subtext
                            formTitle
                            formSubtitle
                            locationIcon {
                                altText
                                sourceUrl
                            }
                            locationText
                            phoneIcon {
                                altText
                                sourceUrl
                            }
                            phoneText
                            emailIcon {
                                altText
                                sourceUrl
                            }
                            emailText
                        }
                    }
                }
                nav_menu: menu(id: "TWVudToy") {
                    menuItems {
                        nodes {
                            label
                            url
                            id
                        }
                    }
                }
                copyrights {
                    nodes {
                        acfCopyrights {
                            copyrightText
                        }
                    }
                }
                socialMediaIcons {
                    nodes {
                        acfSocialMediaIcons {
                            socialMediaIcon {
                                altText
                                sourceUrl
                            }
                            socialMediaLink {
                                target
                                title
                                url
                            }
                        }
                        id
                    }
                }
            }

        }
    `)
    let curr_state_data = data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const[ existingData , updateData ] = useState(curr_state_data);

    // Fetching the Live data from the created WORDPRESS API 

    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;
        
        fetch(`${curr_api}/graphql`,{
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                query:`{
                    contactSections {
                        nodes {
                            acfContact {
                                title
                                subtext
                                formTitle
                                formSubtitle
                                locationIcon {
                                    altText
                                    sourceUrl
                                }
                                googleLocationUrl
                                locationText
                                phoneIcon {
                                    altText
                                    sourceUrl
                                }
                                phoneText
                                emailIcon {
                                    altText
                                    sourceUrl
                                }
                                emailText
                            }
                        }
                    }
                    nav_menu: menu(id: "TWVudToy") {
                        menuItems {
                            nodes {
                                label
                                url
                                id
                            }
                        }
                    }
                    copyrights {
                        nodes {
                            acfCopyrights {
                                copyrightText
                            }
                        }
                    }
                    socialMediaIcons {
                        nodes {
                            acfSocialMediaIcons {
                                socialMediaIcon {
                                    altText
                                    sourceUrl
                                }
                                socialMediaLink {
                                    target
                                    title
                                    url
                                }
                            }
                            id
                        }
                    }
                }`
            }),
        })
        .then(res => res.json())
        .then(data =>  updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));

    }, [])
    let hideThanksPopup = () => {
        var base_url = window.location.origin;  
        window.location.href = base_url+"/contact/thankyou" 
      }

    // Storing the state data in variables to render in Component

    let contact_txt_data = existingData.contactSections.nodes[0];

    let footer_menu = existingData.nav_menu.menuItems.nodes;

    let footer_copyright = existingData.copyrights.nodes[0];

    let socialMediaData = existingData.socialMediaIcons.nodes;


    const [showDropData, setShowDropData] = useState("");

    function toggleActive(index){
        var value = index;
        if(value == showDropData){
            showData = '';
            setShowDropData("")
        }else{
            showData = value;
            setShowDropData(showDropData => value)
        }
        
        
    }

    return(
        <footer id="site_footer">

                <div className="innerwrap">
                {
            window.location!==undefined?
            window.location.search!==''?
              hideThanksPopup()  
            :''
            :''
            }
                    <div className="section_header text-center contact_header">
                        <div className="text_wrap">
                            <h3 className="section_title">{contact_txt_data.acfContact.title}</h3>
                            <p className="slide_descr">{contact_txt_data.acfContact.subtext}</p>
                        </div>
                    </div>

                    <div className="container">
                        <div className="contact_section">
                            <div className="form_wrap">

                                <div className="row">
                                    <div className="col-xs-12 col-sm-5">
                                        <div className="text_section">
                                            <h3 className="title">{contact_txt_data.acfContact.formTitle}</h3>
                                            <p className="subtxt">{contact_txt_data.acfContact.formSubtitle}</p>
                                        </div>

                                        <div className="contact_details">
                                            <ul className="detail_ul">
                                                <li className="detail_item flex_view_xs middle">
                                                    <span className="icon">
                                                        <a href={contact_txt_data.acfContact.googleLocationUrl} target="_blank">
                                                            <img src={contact_txt_data.acfContact.locationIcon.sourceUrl} alt={contact_txt_data.acfContact.locationIcon.altText}/>
                                                        </a>

                                                    </span>
                                                    <span className="txt" dangerouslySetInnerHTML={{ __html: `${contact_txt_data.acfContact.locationText}` }}></span>
                                                </li>

                                                <li className="detail_item flex_view_xs middle">
                                                    <span className="icon">
                                                        
                                                        <a href={`tel:${contact_txt_data.acfContact.phoneText}`}>
                                                            <img src={contact_txt_data.acfContact.phoneIcon.sourceUrl} alt={contact_txt_data.acfContact.phoneIcon.altText}/>
                                                        </a>

                                                    </span>
                                                    <span className="txt"><a href={`tel:${contact_txt_data.acfContact.phoneText}`}>{contact_txt_data.acfContact.phoneText}</a></span>
                                                </li>


                                                <li className="detail_item flex_view_xs middle">
                                                    <span className="icon">
                                                        <a href={`mailto:${contact_txt_data.acfContact.emailText}`}>
                                                        <img src={contact_txt_data.acfContact.emailIcon.sourceUrl} alt={contact_txt_data.acfContact.emailIcon.altText}/>

                                                        </a>

                                                    </span>
                                                    <span className="txt"> <a href={`mailto:${contact_txt_data.acfContact.emailText}`}> {contact_txt_data.acfContact.emailText}</a></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-7">
                                        <form id="contactform" className="contact_form"
                                         //action="https://formsubmit.io/send/aakashsharma19090@gmail.com"
                                         >

                                            {/* <input name="_redirect" type="hidden" id="name" value="http://website.local.zephx.com/"/> */}

                                            <div className="form_group form_txt_group">
                                                <label htmlFor="fname">FIRST NAME</label>
                                                <input type="text" name="fname" id="fname" pattern="[A-Za-z]+" title="Only aplhabets allowed without whitespace" className="input_grp" required/>
                                            </div>

                                            <div className="form_group form_txt_group">
                                                <label htmlFor="lname">LAST NAME</label>
                                                <input type="text" name="lname" id="lname" pattern="[A-Za-z]+" title="Only aplhabets allowed without whitespace" className="input_grp" required/>
                                            </div>

                                            <div className="form_group form_msg_group">
                                                <label htmlFor="email">EMAIL</label>
                                                <input type="email" name="email" id="email" className="input_grp" required/>
                                            </div>

                                            <div className="form_group form_msg_group">
                                                <label htmlFor="msg">MESSAGE</label>
                                                <textarea name="msg" id="msg" rows="1" required></textarea>
                                            </div>
                                            <input name="_formsubmit_id" type="text" style={{display:'none'}}/>

                                            <input type="submit" className="submit_btn" value="Send"/>

                                        </form>
                                    </div>
                                </div>
                                

                            </div>

                            <div className="footer_menu text-center">
                                <ul className="nav_ul">
                                    
                                    {
                                        footer_menu.map((navitem) => {
                                        return <li key={navitem.id} className="nav_li">
                                                <AnchorLink offset='74' 
                                                href={navitem.url} 
                                                className = {showData != "" ? showData == navitem.id ? `nav_url active`  : "nav_url" : "nav_url"}
                                                onClick = {() => toggleActive(navitem.id)}
                                                >
                                                {navitem.label}
                                                </AnchorLink>
                                            </li>

                                        }) 
                                    }



                                    
                                </ul>
                            </div>


                        </div>
                    </div>

                    <div className="copyright_div">
                        <div className="container">
                            <div className="row">

                                <div className="col-sm-6">
                                    <p className="copyright_txt">{footer_copyright.acfCopyrights.copyrightText}</p>
                                </div>

                                <div className="col-sm-6">
                                    <ul className="social_icons text-right">
                                        
                                        {
                                            socialMediaData.reverse().map((item)=>{

                                                return <li key={item.id} className="media_icon">
                                                    <a href={item.acfSocialMediaIcons.socialMediaLink.url} className="social_url" target={item.acfSocialMediaIcons.socialMediaLink.target}>
                                                        <img src={item.acfSocialMediaIcons.socialMediaIcon.sourceUrl} alt={item.acfSocialMediaIcons.socialMediaIcon.altText}/>
                                                    </a>
                                                </li>

                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
        </footer>
    )
}

 
export default Footer