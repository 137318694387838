import React, { useState, useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"
import AnchorLink from 'react-anchor-link-smooth-scroll'
var showData = '';
const Header = () => {
    
    // Querying and storing Data Stored in Gatsby GraphQL query.
    const data_query = useStaticQuery(graphql`
        query {
            wpgraphql {
                headers {
                    nodes {
                        acfSiteLogo {
                            siteLogo {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
                nav_menu: menus(where: { slug: "main-menu" }) {
                    nodes {
                        menuItems {
                            nodes {
                                label
                                url
                                id
                            }
                        }
                    }
                }
                login_menu: menus(where: { slug: "login-menu" }) {
                    nodes {
                        menuItems {
                            nodes {
                                label
                                url
                                id
                            }
                        }
                    }
                }
            }
        }
    `)
    let curr_state_data = data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data

    const [ existingData, updateData ] = useState(curr_state_data)

    const [ isMenuActive, setIsMenuActive ] = useState(false);

    // Fetching the Live data from the created WORDPRESS API 
    
    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;
        
        fetch(`${curr_api}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `
                {
                    headers {
                        nodes {
                            acfSiteLogo {
                                siteLogo {
                                    altText
                                    sourceUrl
                                }
                            }
                        }
                    }
                    nav_menu: menus(where: { slug: "main-menu" }) {
                        nodes {
                            menuItems {
                                nodes {
                                    label
                                    url
                                    id
                                }
                            }
                        }
                    }
                    login_menu: menus(where: { slug: "login-menu" }) {
                        nodes {
                            menuItems {
                                nodes {
                                    label
                                    url
                                    id
                                }
                            }
                        }
                    }
                }
                `
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));

    }, [])
    
   // console.log(existingData)

   // Storing the state data in variables to render in Component

    let logo_data = existingData.headers.nodes[0].acfSiteLogo.siteLogo;

    let nav_data = existingData.nav_menu.nodes[0].menuItems.nodes;

    let login_data = existingData.login_menu.nodes[0].menuItems.nodes;

    const [showDropData, setShowDropData] = useState("");

    function toggleActive(index){
        var value = index;
        if(value == showDropData){
            showData = '';
            setShowDropData("")
        }else{
            showData = value;
            setShowDropData(showDropData => value)
        }
        
        
    }

    const navToggle = () => {
        setIsMenuActive(!isMenuActive)
        console.log(isMenuActive)
    }
    
    return(
        <>
        <header id="desk_header" className="xs-hide">
            <div className="header_wrapper"> 
                <div className="container">
                    <div className="flex_view middle">
                        
                        <div className="col-sm-2">
                            <div className="logo_wrapper">
                                <a href="/" className="logo_url">
                                    <img src={logo_data.sourceUrl} className="logo_img" alt={logo_data.altText} title="Logo"/>

                                </a>
                            </div>
                        </div>

                        <div className="col-sm-8">
                            <div className="nav_menu">
                                <nav className="desk_nav">
                                    <ul className="nav_ul">

                                        {
                                            nav_data.map((navitem) => {
                                            return <li key={navitem.id} className="nav_li">
                                                <AnchorLink offset='74' 
                                                id={navitem.id} 
                                                href={navitem.url} 
                                                //className="nav_url" 
                                                className = {showData != "" ? showData == navitem.id ? `nav_url active`  : "nav_url" : "nav_url"}
                                                onClick={() => toggleActive(navitem.id)}>
                                                {navitem.label}
                                                </AnchorLink>
                                                
                                                </li>

                                            }) 
                                        }


                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div className="col-sm-2">
                            <div className="login_wrapper text-right">
                            
                                <a href={login_data[0].url}>{login_data[0].label}</a><span className="seperator">/</span><a href={login_data[1].url}>{login_data[1].label}</a>
                         
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </header>
        {/* for mobile */}
	<header id="header_sm" className={`xs-show mob_header ${isMenuActive ? 'show_menu' : ''}`}>
		<div className='inner'>
			<div className='container'>
				<div className='flex_view_xs center'>
					
					<div className='col-xs-6'>
						<div className='logo_wrapper'>
                            <a href="/" className="logo_url">
                                <img src={logo_data.sourceUrl} className="logo_img" alt={logo_data.altText} title="Logo"/>

                            </a>
						</div>
					</div>

					<div className='col-xs-6'>
						<div className='toggle_div text-right'>

							<div id='nav_toggle_icon' className={`${isMenuActive ? 'menu_visible' : '' }`}  onClick={navToggle}>
								<div className="bar1"></div>
								<div className="bar2"></div>
								<div className="bar3"></div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>

		<div className='navbar_xs'>
			<div className='container'>
                <nav className="mob_nav">
                    <ul className="nav_ul">

                        {
                            nav_data.map((navitem) => {
                            return <li key={navitem.id} className="nav_li">
                                <AnchorLink offset='74' 
                                id={navitem.id} 
                                href={navitem.url} 
                                //className="nav_url" 
                                className = {showData != "" ? showData == navitem.id ? `nav_url active`  : "nav_url" : "nav_url"}
                                onClick={() => toggleActive(navitem.id)}>
                                {navitem.label}
                                </AnchorLink>
                                
                                </li>

                            }) 
                        }


                    </ul>
                </nav>                       
			</div>									
		</div>
	</header>


    </>
    )
    
}

export default Header;

